import styled from 'styled-components';
import GlobalStyle from './globalStyles';
import {useEffect, useState} from 'react';
import {TextField} from '@mui/material';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ImageUploader from './ImageUploader';
import Button from '@mui/material/Button';
import axios from 'axios';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import { URL } from './config';
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 300,
    },
  },
};



const names = [
  'Дерево',
  'Мешки',
  'Бетон',
  'Пленки',
  'Окна/Двери',
  'ДСП/МДФ',
  'Картон',
  'Трубы',
  'Металл',
  'Утеплители',
];

const App = () => {

  const [images, setImages] = useState([]);
  const [cube, setCube] = useState('');
  const [car, setCar] = useState('');
  const [predpr, setPredpr] = useState('');
  const [types, setTypes] = useState([]);
  const [newTypes, setNewTypes] = useState('');
  const [more, setMore] = useState(false);
  const [perevoz, setPerevoz] = useState([]);

  const [load, setLoad] = useState(false);

  const [o ,setO] = useState('');
  const [i ,setI] = useState('');
  const [f ,setF] = useState('');

  const handleClear = () => {
    setImages([]);
    setCube('');
    setCar('');
    setPredpr('');
    setTypes([]);
    setNewTypes('');
    setMore(false);
    setO('');
    setF('');
    setI('');
  }

  const handleChange = (item) => {
    setTypes(prevSelectedItems => {
      if (prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter(i => i !== item);
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };

  const handleType = (e, setter) => {
    setter(e.target.value);
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  
  const handleClick = async () => {
  setLoad(true);
  try {

    const formData = new FormData();
    images.forEach(image => formData.append('images', image.file));
    const uploadResponse = await axios.post(`/api/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    const uploadedImages = uploadResponse.data.files; // .url
    
    // Отправка данных формы
    const data = {
      fio: capitalizeFirstLetter(f)+' '+capitalizeFirstLetter(i)+' '+capitalizeFirstLetter(o),
      cube,
      car: car.toUpperCase(),
      predpr,
      types: [...types, ...newTypes.split(' ')],
      images: uploadedImages
    };
    
    await axios.post(`/api/addData`, data);
    alert('Данные успешно отправлены');
    handleClear();
  } catch (error) {
    console.error('Ошибка при отправке данных', error);
    alert('Произошла ошибка при отправке данных');
  } finally {
    setLoad(false);
  }
};

  useEffect(()=>{
    const fetch = async () => {
      try {
        const {data} = await axios.get(`/api/perevoz`);
        setPerevoz(data);
      } catch (e) {
        console.log(e)
      }
    }
    fetch();
  },[])

  useEffect(()=>{
    setNewTypes('');
  }, [more])

  return (
    <>
     <GlobalStyle />
     <Wrapper>
        <Body>
           <Form>
             <div style={{display: 'flex', gap: '16px'}}>
                <TextField style={{width:'50%'}} label="Объем" id="outlined-start-adornment" onChange={(e) => handleType(e, setCube)} value={cube} InputProps={{
                    startAdornment: <InputAdornment position="start">Куб.</InputAdornment>,
                  }}/>
                <TextField style={{width:'50%'}} id="outlined-basic" label="Номер автомобиля" variant="outlined" onChange={(e) => handleType(e, setCar)} value={car.toUpperCase()}/>
             </div>
             <div style={{display: 'flex', gap: '16px'}}>
              <TextField style={{width:'33.33%'}} id="outlined-basic" label="Фамилия" variant="outlined" onChange={(e) => handleType(e, setF)} value={f}/>
               <TextField style={{width:'33.33%'}} id="outlined-basic" label="Имя" variant="outlined" onChange={(e) => handleType(e, setI)} value={i}/>
               <TextField style={{width:'33.33%'}} id="outlined-basic" label="Отчество" variant="outlined" onChange={(e) => handleType(e, setO)} value={o}/>
             </div>
             <FormControl fullWidth>
               <InputLabel id="demo-simple-select-label">Перевозчик</InputLabel>
               <Select
                   labelId="demo-simple-select-label"
                   id="demo-simple-select"
                   value={predpr}
                   label="Перевозчик"
                   onChange={(e) => handleType(e, setPredpr)}
               >
                 {perevoz.map((item, index) => (
                     <MenuItem key={index} value={item}>{item}</MenuItem>
                 ))}
               </Select>
             </FormControl>

              <FormControl sx={{ m: 0 }}>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: '14px'}}>
                  {names.map((item,index) => (
                      <Item
                          key={index}
                          onClick={() => handleChange(item)}
                          className={types.includes(item) ? 'selected' : ''}
                      >
                        {item}
                      </Item>
                  ))}

                  <Item
                      onClick={() => setMore(!more)}
                      className={more ? 'selected' : ''}
                  >
                    {more ? 'Еще' : 'Еще »'}
                  </Item>
                </div>
              </FormControl>
             {more &&
              <div style={{width: '100%'}}>
                <TextField style={{width: '100%'}} label="Новые виды" variant="outlined" onChange={(e) => handleType(e, setNewTypes)} value={newTypes}/>
                <FormHelperText style={{width: '100%'}}>Через пробел</FormHelperText>
              </div>
             }
              <ImageUploader images={images} setImages={setImages}/> 
              <Button disabled={load} variant="contained" color="success" onClick={handleClick}>Отправить</Button>
           </Form>
        </Body>
    </Wrapper>
    </>
    
  )
}

export default App

const Wrapper = styled.div`
  padding: 0;
  margin: 0;
`

const Body = styled.div`
  padding: 20px;
  width: 100%;
  max-width: 700px;
  height: 300px;
  margin: 0 auto;
`
const Form = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
`
const Item = styled.div`
  padding-inline: 14px;
  max-width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  white-space: nowrap;
  cursor: unset;
  outline: 0;
  text-decoration: none;
  border: 0;
  vertical-align: middle;
  box-sizing: border-box;

  &.selected {
    color: rgba(255, 255, 255, 0.87);
    background-color: rgb(110, 185, 59);
  }
`