// MultiImageUploader.js
import { Box, Button, IconButton, Typography } from '@mui/material';
import { PhotoCamera, Delete } from '@mui/icons-material';

const MultiImageUploader = ({images, setImages}) => {
  

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    setImages(prevImages => [...prevImages, ...newImages]);
  };

  const handleImageRemove = (index) => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  return (
    <Box>
      <Button
        variant="contained"
        component="label"
        startIcon={<PhotoCamera />}
      >
        Загрузить фото
        <input
          type="file"
          accept="image/*"
          multiple
          hidden
          onChange={handleImageChange}
        />
      </Button>
      {images.length > 0 ? (
        <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
          {images.map((image, index) => (
            <Box key={index} position="relative" display="inline-block">
              <img src={image.preview} alt={`Preview ${index}`} style={{ maxWidth: '150px', maxHeight: '150px' }} />
              <IconButton
                aria-label="delete"
                onClick={() => handleImageRemove(index)}
                style={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: 'rgba(255, 255, 255, 0.7)'
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography variant="body2" color="textSecondary" mt={2}>
          Нет загруженных фото
        </Typography>
      )}
    </Box>
  );
};

export default MultiImageUploader;
